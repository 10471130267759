"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTravellersBirthdatesBookingInput = instanceOfTravellersBirthdatesBookingInput;
exports.TravellersBirthdatesBookingInputFromJSON = TravellersBirthdatesBookingInputFromJSON;
exports.TravellersBirthdatesBookingInputFromJSONTyped = TravellersBirthdatesBookingInputFromJSONTyped;
exports.TravellersBirthdatesBookingInputToJSON = TravellersBirthdatesBookingInputToJSON;
var TravellersBirthdatesBookingInputType_1 = require("./TravellersBirthdatesBookingInputType");
/**
 * Check if a given object implements the TravellersBirthdatesBookingInput interface.
 */
function instanceOfTravellersBirthdatesBookingInput(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
function TravellersBirthdatesBookingInputFromJSON(json) {
    return TravellersBirthdatesBookingInputFromJSONTyped(json, false);
}
function TravellersBirthdatesBookingInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TravellersBirthdatesBookingInputType_1.TravellersBirthdatesBookingInputTypeFromJSON)(json['type']),
    };
}
function TravellersBirthdatesBookingInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TravellersBirthdatesBookingInputType_1.TravellersBirthdatesBookingInputTypeToJSON)(value.type),
    };
}
