"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTravellersGendersBookingChoice = instanceOfTravellersGendersBookingChoice;
exports.TravellersGendersBookingChoiceFromJSON = TravellersGendersBookingChoiceFromJSON;
exports.TravellersGendersBookingChoiceFromJSONTyped = TravellersGendersBookingChoiceFromJSONTyped;
exports.TravellersGendersBookingChoiceToJSON = TravellersGendersBookingChoiceToJSON;
var IdTravellerGender_1 = require("./IdTravellerGender");
var TravellersGendersBookingChoiceType_1 = require("./TravellersGendersBookingChoiceType");
/**
 * Check if a given object implements the TravellersGendersBookingChoice interface.
 */
function instanceOfTravellersGendersBookingChoice(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "genders" in value;
    return isInstance;
}
function TravellersGendersBookingChoiceFromJSON(json) {
    return TravellersGendersBookingChoiceFromJSONTyped(json, false);
}
function TravellersGendersBookingChoiceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TravellersGendersBookingChoiceType_1.TravellersGendersBookingChoiceTypeFromJSON)(json['type']),
        'genders': (json['genders'].map(IdTravellerGender_1.IdTravellerGenderFromJSON)),
    };
}
function TravellersGendersBookingChoiceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TravellersGendersBookingChoiceType_1.TravellersGendersBookingChoiceTypeToJSON)(value.type),
        'genders': (value.genders.map(IdTravellerGender_1.IdTravellerGenderToJSON)),
    };
}
