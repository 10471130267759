"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfBookingStay = instanceOfBookingStay;
exports.BookingStayFromJSON = BookingStayFromJSON;
exports.BookingStayFromJSONTyped = BookingStayFromJSONTyped;
exports.BookingStayToJSON = BookingStayToJSON;
var runtime_1 = require("../runtime");
var BoardType_1 = require("./BoardType");
var CancellationTimelinePolicy_1 = require("./CancellationTimelinePolicy");
var LegalNotice_1 = require("./LegalNotice");
var Location_1 = require("./Location");
var OptionTimeDateRange_1 = require("./OptionTimeDateRange");
/**
 * Check if a given object implements the BookingStay interface.
 */
function instanceOfBookingStay(value) {
    var isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "location" in value;
    isInstance = isInstance && "checkInOutTimes" in value;
    isInstance = isInstance && "adultCount" in value;
    isInstance = isInstance && "roomCount" in value;
    isInstance = isInstance && "roomName" in value;
    isInstance = isInstance && "boardType" in value;
    isInstance = isInstance && "cancellationTimeline" in value;
    isInstance = isInstance && "conditions" in value;
    return isInstance;
}
function BookingStayFromJSON(json) {
    return BookingStayFromJSONTyped(json, false);
}
function BookingStayFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'location': (0, Location_1.LocationFromJSON)(json['location']),
        'starRating': !(0, runtime_1.exists)(json, 'starRating') ? undefined : json['starRating'],
        'photo': !(0, runtime_1.exists)(json, 'photo') ? undefined : json['photo'],
        'checkInOutTimes': (0, OptionTimeDateRange_1.OptionTimeDateRangeFromJSON)(json['checkInOutTimes']),
        'adultCount': json['adultCount'],
        'roomCount': json['roomCount'],
        'roomName': json['roomName'],
        'boardType': (0, BoardType_1.BoardTypeFromJSON)(json['boardType']),
        'keyCollection': !(0, runtime_1.exists)(json, 'keyCollection') ? undefined : json['keyCollection'],
        'supportedLoyaltyProgramme': !(0, runtime_1.exists)(json, 'supportedLoyaltyProgramme') ? undefined : json['supportedLoyaltyProgramme'],
        'cancellationTimeline': (json['cancellationTimeline'].map(CancellationTimelinePolicy_1.CancellationTimelinePolicyFromJSON)),
        'conditions': (json['conditions'].map(LegalNotice_1.LegalNoticeFromJSON)),
    };
}
function BookingStayToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'location': (0, Location_1.LocationToJSON)(value.location),
        'starRating': value.starRating,
        'photo': value.photo,
        'checkInOutTimes': (0, OptionTimeDateRange_1.OptionTimeDateRangeToJSON)(value.checkInOutTimes),
        'adultCount': value.adultCount,
        'roomCount': value.roomCount,
        'roomName': value.roomName,
        'boardType': (0, BoardType_1.BoardTypeToJSON)(value.boardType),
        'keyCollection': value.keyCollection,
        'supportedLoyaltyProgramme': value.supportedLoyaltyProgramme,
        'cancellationTimeline': (value.cancellationTimeline.map(CancellationTimelinePolicy_1.CancellationTimelinePolicyToJSON)),
        'conditions': (value.conditions.map(LegalNotice_1.LegalNoticeToJSON)),
    };
}
