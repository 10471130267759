"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTravellersPassportsBookingChoice = instanceOfTravellersPassportsBookingChoice;
exports.TravellersPassportsBookingChoiceFromJSON = TravellersPassportsBookingChoiceFromJSON;
exports.TravellersPassportsBookingChoiceFromJSONTyped = TravellersPassportsBookingChoiceFromJSONTyped;
exports.TravellersPassportsBookingChoiceToJSON = TravellersPassportsBookingChoiceToJSON;
var IdTravellerPassport_1 = require("./IdTravellerPassport");
var TravellersPassportsBookingChoiceType_1 = require("./TravellersPassportsBookingChoiceType");
/**
 * Check if a given object implements the TravellersPassportsBookingChoice interface.
 */
function instanceOfTravellersPassportsBookingChoice(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "passports" in value;
    return isInstance;
}
function TravellersPassportsBookingChoiceFromJSON(json) {
    return TravellersPassportsBookingChoiceFromJSONTyped(json, false);
}
function TravellersPassportsBookingChoiceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TravellersPassportsBookingChoiceType_1.TravellersPassportsBookingChoiceTypeFromJSON)(json['type']),
        'passports': (json['passports'].map(IdTravellerPassport_1.IdTravellerPassportFromJSON)),
    };
}
function TravellersPassportsBookingChoiceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TravellersPassportsBookingChoiceType_1.TravellersPassportsBookingChoiceTypeToJSON)(value.type),
        'passports': (value.passports.map(IdTravellerPassport_1.IdTravellerPassportToJSON)),
    };
}
