"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfBookingInfo = instanceOfBookingInfo;
exports.BookingInfoFromJSON = BookingInfoFromJSON;
exports.BookingInfoFromJSONTyped = BookingInfoFromJSONTyped;
exports.BookingInfoToJSON = BookingInfoToJSON;
var runtime_1 = require("../runtime");
var BookingProduct_1 = require("./BookingProduct");
var LegalNotice_1 = require("./LegalNotice");
var Pricing_1 = require("./Pricing");
var TravellerLoyaltyProgramme_1 = require("./TravellerLoyaltyProgramme");
/**
 * Check if a given object implements the BookingInfo interface.
 */
function instanceOfBookingInfo(value) {
    var isInstance = true;
    isInstance = isInstance && "pricing" in value;
    isInstance = isInstance && "products" in value;
    isInstance = isInstance && "travellerCount" in value;
    isInstance = isInstance && "appliedLoyaltyProgrammes" in value;
    isInstance = isInstance && "legalNotices" in value;
    return isInstance;
}
function BookingInfoFromJSON(json) {
    return BookingInfoFromJSONTyped(json, false);
}
function BookingInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'pricing': (0, Pricing_1.PricingFromJSON)(json['pricing']),
        'products': (json['products'].map(BookingProduct_1.BookingProductFromJSON)),
        'travellerCount': json['travellerCount'],
        'travellerNames': !(0, runtime_1.exists)(json, 'travellerNames') ? undefined : json['travellerNames'],
        'appliedLoyaltyProgrammes': (json['appliedLoyaltyProgrammes'].map(TravellerLoyaltyProgramme_1.TravellerLoyaltyProgrammeFromJSON)),
        'legalNotices': (json['legalNotices'].map(LegalNotice_1.LegalNoticeFromJSON)),
    };
}
function BookingInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'pricing': (0, Pricing_1.PricingToJSON)(value.pricing),
        'products': (value.products.map(BookingProduct_1.BookingProductToJSON)),
        'travellerCount': value.travellerCount,
        'travellerNames': value.travellerNames,
        'appliedLoyaltyProgrammes': (value.appliedLoyaltyProgrammes.map(TravellerLoyaltyProgramme_1.TravellerLoyaltyProgrammeToJSON)),
        'legalNotices': (value.legalNotices.map(LegalNotice_1.LegalNoticeToJSON)),
    };
}
