"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TravellersLoyaltyProgrammesBookingChoiceType = void 0;
exports.TravellersLoyaltyProgrammesBookingChoiceTypeFromJSON = TravellersLoyaltyProgrammesBookingChoiceTypeFromJSON;
exports.TravellersLoyaltyProgrammesBookingChoiceTypeFromJSONTyped = TravellersLoyaltyProgrammesBookingChoiceTypeFromJSONTyped;
exports.TravellersLoyaltyProgrammesBookingChoiceTypeToJSON = TravellersLoyaltyProgrammesBookingChoiceTypeToJSON;
/**
 *
 * @export
 */
exports.TravellersLoyaltyProgrammesBookingChoiceType = {
    TravellersLoyaltyProgrammes: 'travellersLoyaltyProgrammes'
};
function TravellersLoyaltyProgrammesBookingChoiceTypeFromJSON(json) {
    return TravellersLoyaltyProgrammesBookingChoiceTypeFromJSONTyped(json, false);
}
function TravellersLoyaltyProgrammesBookingChoiceTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function TravellersLoyaltyProgrammesBookingChoiceTypeToJSON(value) {
    return value;
}
