"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTravellersTitlesBookingInput = instanceOfTravellersTitlesBookingInput;
exports.TravellersTitlesBookingInputFromJSON = TravellersTitlesBookingInputFromJSON;
exports.TravellersTitlesBookingInputFromJSONTyped = TravellersTitlesBookingInputFromJSONTyped;
exports.TravellersTitlesBookingInputToJSON = TravellersTitlesBookingInputToJSON;
var TravellersTitlesBookingInputType_1 = require("./TravellersTitlesBookingInputType");
/**
 * Check if a given object implements the TravellersTitlesBookingInput interface.
 */
function instanceOfTravellersTitlesBookingInput(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
function TravellersTitlesBookingInputFromJSON(json) {
    return TravellersTitlesBookingInputFromJSONTyped(json, false);
}
function TravellersTitlesBookingInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TravellersTitlesBookingInputType_1.TravellersTitlesBookingInputTypeFromJSON)(json['type']),
    };
}
function TravellersTitlesBookingInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TravellersTitlesBookingInputType_1.TravellersTitlesBookingInputTypeToJSON)(value.type),
    };
}
