"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractSignUpMethodType = void 0;
exports.ContractSignUpMethodTypeFromJSON = ContractSignUpMethodTypeFromJSON;
exports.ContractSignUpMethodTypeFromJSONTyped = ContractSignUpMethodTypeFromJSONTyped;
exports.ContractSignUpMethodTypeToJSON = ContractSignUpMethodTypeToJSON;
/**
 *
 * @export
 */
exports.ContractSignUpMethodType = {
    Contract: 'contract'
};
function ContractSignUpMethodTypeFromJSON(json) {
    return ContractSignUpMethodTypeFromJSONTyped(json, false);
}
function ContractSignUpMethodTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function ContractSignUpMethodTypeToJSON(value) {
    return value;
}
