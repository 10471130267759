"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfIdTravellerPassport = instanceOfIdTravellerPassport;
exports.IdTravellerPassportFromJSON = IdTravellerPassportFromJSON;
exports.IdTravellerPassportFromJSONTyped = IdTravellerPassportFromJSONTyped;
exports.IdTravellerPassportToJSON = IdTravellerPassportToJSON;
var TravellerProfilePassportDetails_1 = require("./TravellerProfilePassportDetails");
/**
 * Check if a given object implements the IdTravellerPassport interface.
 */
function instanceOfIdTravellerPassport(value) {
    var isInstance = true;
    isInstance = isInstance && "travellerId" in value;
    isInstance = isInstance && "passport" in value;
    return isInstance;
}
function IdTravellerPassportFromJSON(json) {
    return IdTravellerPassportFromJSONTyped(json, false);
}
function IdTravellerPassportFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'travellerId': json['travellerId'],
        'passport': (0, TravellerProfilePassportDetails_1.TravellerProfilePassportDetailsFromJSON)(json['passport']),
    };
}
function IdTravellerPassportToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'travellerId': value.travellerId,
        'passport': (0, TravellerProfilePassportDetails_1.TravellerProfilePassportDetailsToJSON)(value.passport),
    };
}
