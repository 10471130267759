"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TravellersMealPreferencesBookingInputType = void 0;
exports.TravellersMealPreferencesBookingInputTypeFromJSON = TravellersMealPreferencesBookingInputTypeFromJSON;
exports.TravellersMealPreferencesBookingInputTypeFromJSONTyped = TravellersMealPreferencesBookingInputTypeFromJSONTyped;
exports.TravellersMealPreferencesBookingInputTypeToJSON = TravellersMealPreferencesBookingInputTypeToJSON;
/**
 *
 * @export
 */
exports.TravellersMealPreferencesBookingInputType = {
    TravellersMealPreferences: 'travellersMealPreferences'
};
function TravellersMealPreferencesBookingInputTypeFromJSON(json) {
    return TravellersMealPreferencesBookingInputTypeFromJSONTyped(json, false);
}
function TravellersMealPreferencesBookingInputTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function TravellersMealPreferencesBookingInputTypeToJSON(value) {
    return value;
}
