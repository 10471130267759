"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfLoginUrlRedirectLocalhost = instanceOfLoginUrlRedirectLocalhost;
exports.LoginUrlRedirectLocalhostFromJSON = LoginUrlRedirectLocalhostFromJSON;
exports.LoginUrlRedirectLocalhostFromJSONTyped = LoginUrlRedirectLocalhostFromJSONTyped;
exports.LoginUrlRedirectLocalhostToJSON = LoginUrlRedirectLocalhostToJSON;
var runtime_1 = require("../runtime");
var LoginUrlRedirectLocalhostType_1 = require("./LoginUrlRedirectLocalhostType");
var OrgUrlName_1 = require("./OrgUrlName");
/**
 * Check if a given object implements the LoginUrlRedirectLocalhost interface.
 */
function instanceOfLoginUrlRedirectLocalhost(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "port" in value;
    return isInstance;
}
function LoginUrlRedirectLocalhostFromJSON(json) {
    return LoginUrlRedirectLocalhostFromJSONTyped(json, false);
}
function LoginUrlRedirectLocalhostFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, LoginUrlRedirectLocalhostType_1.LoginUrlRedirectLocalhostTypeFromJSON)(json['type']),
        'port': json['port'],
        'orgUrlName': !(0, runtime_1.exists)(json, 'orgUrlName') ? undefined : (0, OrgUrlName_1.OrgUrlNameFromJSON)(json['orgUrlName']),
    };
}
function LoginUrlRedirectLocalhostToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, LoginUrlRedirectLocalhostType_1.LoginUrlRedirectLocalhostTypeToJSON)(value.type),
        'port': value.port,
        'orgUrlName': (0, OrgUrlName_1.OrgUrlNameToJSON)(value.orgUrlName),
    };
}
