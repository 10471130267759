"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTicketBase = instanceOfTicketBase;
exports.TicketBaseFromJSON = TicketBaseFromJSON;
exports.TicketBaseFromJSONTyped = TicketBaseFromJSONTyped;
exports.TicketBaseToJSON = TicketBaseToJSON;
var runtime_1 = require("../runtime");
var FormattedDate_1 = require("./FormattedDate");
var TicketProductType_1 = require("./TicketProductType");
/**
 * Check if a given object implements the TicketBase interface.
 */
function instanceOfTicketBase(value) {
    var isInstance = true;
    isInstance = isInstance && "productType" in value;
    return isInstance;
}
function TicketBaseFromJSON(json) {
    return TicketBaseFromJSONTyped(json, false);
}
function TicketBaseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'productType': (0, TicketProductType_1.TicketProductTypeFromJSON)(json['productType']),
        'confirmationDate': !(0, runtime_1.exists)(json, 'confirmationDate') ? undefined : (0, FormattedDate_1.FormattedDateFromJSON)(json['confirmationDate']),
    };
}
function TicketBaseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'productType': (0, TicketProductType_1.TicketProductTypeToJSON)(value.productType),
        'confirmationDate': (0, FormattedDate_1.FormattedDateToJSON)(value.confirmationDate),
    };
}
