"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RailcardConditionsDescriptionType = void 0;
exports.RailcardConditionsDescriptionTypeFromJSON = RailcardConditionsDescriptionTypeFromJSON;
exports.RailcardConditionsDescriptionTypeFromJSONTyped = RailcardConditionsDescriptionTypeFromJSONTyped;
exports.RailcardConditionsDescriptionTypeToJSON = RailcardConditionsDescriptionTypeToJSON;
/**
 *
 * @export
 */
exports.RailcardConditionsDescriptionType = {
    Railcard: 'railcard'
};
function RailcardConditionsDescriptionTypeFromJSON(json) {
    return RailcardConditionsDescriptionTypeFromJSONTyped(json, false);
}
function RailcardConditionsDescriptionTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function RailcardConditionsDescriptionTypeToJSON(value) {
    return value;
}
