"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfIdTravellerMealPreference = instanceOfIdTravellerMealPreference;
exports.IdTravellerMealPreferenceFromJSON = IdTravellerMealPreferenceFromJSON;
exports.IdTravellerMealPreferenceFromJSONTyped = IdTravellerMealPreferenceFromJSONTyped;
exports.IdTravellerMealPreferenceToJSON = IdTravellerMealPreferenceToJSON;
var TravellerMeal_1 = require("./TravellerMeal");
/**
 * Check if a given object implements the IdTravellerMealPreference interface.
 */
function instanceOfIdTravellerMealPreference(value) {
    var isInstance = true;
    isInstance = isInstance && "travellerId" in value;
    isInstance = isInstance && "mealPreference" in value;
    return isInstance;
}
function IdTravellerMealPreferenceFromJSON(json) {
    return IdTravellerMealPreferenceFromJSONTyped(json, false);
}
function IdTravellerMealPreferenceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'travellerId': json['travellerId'],
        'mealPreference': (0, TravellerMeal_1.TravellerMealFromJSON)(json['mealPreference']),
    };
}
function IdTravellerMealPreferenceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'travellerId': value.travellerId,
        'mealPreference': (0, TravellerMeal_1.TravellerMealToJSON)(value.mealPreference),
    };
}
