"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfStaySpecialRequestsBookingInput = instanceOfStaySpecialRequestsBookingInput;
exports.StaySpecialRequestsBookingInputFromJSON = StaySpecialRequestsBookingInputFromJSON;
exports.StaySpecialRequestsBookingInputFromJSONTyped = StaySpecialRequestsBookingInputFromJSONTyped;
exports.StaySpecialRequestsBookingInputToJSON = StaySpecialRequestsBookingInputToJSON;
var StaySpecialRequestsBookingInputType_1 = require("./StaySpecialRequestsBookingInputType");
/**
 * Check if a given object implements the StaySpecialRequestsBookingInput interface.
 */
function instanceOfStaySpecialRequestsBookingInput(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
function StaySpecialRequestsBookingInputFromJSON(json) {
    return StaySpecialRequestsBookingInputFromJSONTyped(json, false);
}
function StaySpecialRequestsBookingInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, StaySpecialRequestsBookingInputType_1.StaySpecialRequestsBookingInputTypeFromJSON)(json['type']),
    };
}
function StaySpecialRequestsBookingInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, StaySpecialRequestsBookingInputType_1.StaySpecialRequestsBookingInputTypeToJSON)(value.type),
    };
}
