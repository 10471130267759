"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnknownLegalNoticeConditionType = void 0;
exports.UnknownLegalNoticeConditionTypeFromJSON = UnknownLegalNoticeConditionTypeFromJSON;
exports.UnknownLegalNoticeConditionTypeFromJSONTyped = UnknownLegalNoticeConditionTypeFromJSONTyped;
exports.UnknownLegalNoticeConditionTypeToJSON = UnknownLegalNoticeConditionTypeToJSON;
/**
 *
 * @export
 */
exports.UnknownLegalNoticeConditionType = {
    Unknown: 'unknown'
};
function UnknownLegalNoticeConditionTypeFromJSON(json) {
    return UnknownLegalNoticeConditionTypeFromJSONTyped(json, false);
}
function UnknownLegalNoticeConditionTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function UnknownLegalNoticeConditionTypeToJSON(value) {
    return value;
}
