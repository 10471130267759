"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingInputFromJSON = BookingInputFromJSON;
exports.BookingInputFromJSONTyped = BookingInputFromJSONTyped;
exports.BookingInputToJSON = BookingInputToJSON;
var BookerEmailBookingInput_1 = require("./BookerEmailBookingInput");
var BookerPhoneNumberBookingInput_1 = require("./BookerPhoneNumberBookingInput");
var FlightSeatingOptionsBookingInput_1 = require("./FlightSeatingOptionsBookingInput");
var PurchaseOrderNumberBookingInput_1 = require("./PurchaseOrderNumberBookingInput");
var StaySpecialRequestsBookingInput_1 = require("./StaySpecialRequestsBookingInput");
var TicketCollectionTypeBookingInput_1 = require("./TicketCollectionTypeBookingInput");
var TrainSeatingOptionsBookingInput_1 = require("./TrainSeatingOptionsBookingInput");
var TravellersBirthdatesBookingInput_1 = require("./TravellersBirthdatesBookingInput");
var TravellersEmailsBookingInput_1 = require("./TravellersEmailsBookingInput");
var TravellersFirstNamesBookingInput_1 = require("./TravellersFirstNamesBookingInput");
var TravellersGendersBookingInput_1 = require("./TravellersGendersBookingInput");
var TravellersLoyaltyProgrammesBookingInput_1 = require("./TravellersLoyaltyProgrammesBookingInput");
var TravellersMealPreferencesBookingInput_1 = require("./TravellersMealPreferencesBookingInput");
var TravellersPassportsBookingInput_1 = require("./TravellersPassportsBookingInput");
var TravellersPhonesBookingInput_1 = require("./TravellersPhonesBookingInput");
var TravellersSurnamesBookingInput_1 = require("./TravellersSurnamesBookingInput");
var TravellersTitlesBookingInput_1 = require("./TravellersTitlesBookingInput");
function BookingInputFromJSON(json) {
    return BookingInputFromJSONTyped(json, false);
}
function BookingInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'bookerEmail':
            return __assign(__assign({}, (0, BookerEmailBookingInput_1.BookerEmailBookingInputFromJSONTyped)(json, true)), { type: 'bookerEmail' });
        case 'bookerPhoneNumber':
            return __assign(__assign({}, (0, BookerPhoneNumberBookingInput_1.BookerPhoneNumberBookingInputFromJSONTyped)(json, true)), { type: 'bookerPhoneNumber' });
        case 'flightSeatingOptions':
            return __assign(__assign({}, (0, FlightSeatingOptionsBookingInput_1.FlightSeatingOptionsBookingInputFromJSONTyped)(json, true)), { type: 'flightSeatingOptions' });
        case 'purchaseOrderNumber':
            return __assign(__assign({}, (0, PurchaseOrderNumberBookingInput_1.PurchaseOrderNumberBookingInputFromJSONTyped)(json, true)), { type: 'purchaseOrderNumber' });
        case 'staySpecialRequests':
            return __assign(__assign({}, (0, StaySpecialRequestsBookingInput_1.StaySpecialRequestsBookingInputFromJSONTyped)(json, true)), { type: 'staySpecialRequests' });
        case 'ticketCollectionType':
            return __assign(__assign({}, (0, TicketCollectionTypeBookingInput_1.TicketCollectionTypeBookingInputFromJSONTyped)(json, true)), { type: 'ticketCollectionType' });
        case 'trainSeatingOptions':
            return __assign(__assign({}, (0, TrainSeatingOptionsBookingInput_1.TrainSeatingOptionsBookingInputFromJSONTyped)(json, true)), { type: 'trainSeatingOptions' });
        case 'travellersBirthdates':
            return __assign(__assign({}, (0, TravellersBirthdatesBookingInput_1.TravellersBirthdatesBookingInputFromJSONTyped)(json, true)), { type: 'travellersBirthdates' });
        case 'travellersEmails':
            return __assign(__assign({}, (0, TravellersEmailsBookingInput_1.TravellersEmailsBookingInputFromJSONTyped)(json, true)), { type: 'travellersEmails' });
        case 'travellersFirstNames':
            return __assign(__assign({}, (0, TravellersFirstNamesBookingInput_1.TravellersFirstNamesBookingInputFromJSONTyped)(json, true)), { type: 'travellersFirstNames' });
        case 'travellersGenders':
            return __assign(__assign({}, (0, TravellersGendersBookingInput_1.TravellersGendersBookingInputFromJSONTyped)(json, true)), { type: 'travellersGenders' });
        case 'travellersLoyaltyProgrammes':
            return __assign(__assign({}, (0, TravellersLoyaltyProgrammesBookingInput_1.TravellersLoyaltyProgrammesBookingInputFromJSONTyped)(json, true)), { type: 'travellersLoyaltyProgrammes' });
        case 'travellersMealPreferences':
            return __assign(__assign({}, (0, TravellersMealPreferencesBookingInput_1.TravellersMealPreferencesBookingInputFromJSONTyped)(json, true)), { type: 'travellersMealPreferences' });
        case 'travellersPassports':
            return __assign(__assign({}, (0, TravellersPassportsBookingInput_1.TravellersPassportsBookingInputFromJSONTyped)(json, true)), { type: 'travellersPassports' });
        case 'travellersPhones':
            return __assign(__assign({}, (0, TravellersPhonesBookingInput_1.TravellersPhonesBookingInputFromJSONTyped)(json, true)), { type: 'travellersPhones' });
        case 'travellersSurnames':
            return __assign(__assign({}, (0, TravellersSurnamesBookingInput_1.TravellersSurnamesBookingInputFromJSONTyped)(json, true)), { type: 'travellersSurnames' });
        case 'travellersTitles':
            return __assign(__assign({}, (0, TravellersTitlesBookingInput_1.TravellersTitlesBookingInputFromJSONTyped)(json, true)), { type: 'travellersTitles' });
        default:
            throw new Error("No variant of BookingInput exists with 'type=".concat(json['type'], "'"));
    }
}
function BookingInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'bookerEmail':
            return (0, BookerEmailBookingInput_1.BookerEmailBookingInputToJSON)(value);
        case 'bookerPhoneNumber':
            return (0, BookerPhoneNumberBookingInput_1.BookerPhoneNumberBookingInputToJSON)(value);
        case 'flightSeatingOptions':
            return (0, FlightSeatingOptionsBookingInput_1.FlightSeatingOptionsBookingInputToJSON)(value);
        case 'purchaseOrderNumber':
            return (0, PurchaseOrderNumberBookingInput_1.PurchaseOrderNumberBookingInputToJSON)(value);
        case 'staySpecialRequests':
            return (0, StaySpecialRequestsBookingInput_1.StaySpecialRequestsBookingInputToJSON)(value);
        case 'ticketCollectionType':
            return (0, TicketCollectionTypeBookingInput_1.TicketCollectionTypeBookingInputToJSON)(value);
        case 'trainSeatingOptions':
            return (0, TrainSeatingOptionsBookingInput_1.TrainSeatingOptionsBookingInputToJSON)(value);
        case 'travellersBirthdates':
            return (0, TravellersBirthdatesBookingInput_1.TravellersBirthdatesBookingInputToJSON)(value);
        case 'travellersEmails':
            return (0, TravellersEmailsBookingInput_1.TravellersEmailsBookingInputToJSON)(value);
        case 'travellersFirstNames':
            return (0, TravellersFirstNamesBookingInput_1.TravellersFirstNamesBookingInputToJSON)(value);
        case 'travellersGenders':
            return (0, TravellersGendersBookingInput_1.TravellersGendersBookingInputToJSON)(value);
        case 'travellersLoyaltyProgrammes':
            return (0, TravellersLoyaltyProgrammesBookingInput_1.TravellersLoyaltyProgrammesBookingInputToJSON)(value);
        case 'travellersMealPreferences':
            return (0, TravellersMealPreferencesBookingInput_1.TravellersMealPreferencesBookingInputToJSON)(value);
        case 'travellersPassports':
            return (0, TravellersPassportsBookingInput_1.TravellersPassportsBookingInputToJSON)(value);
        case 'travellersPhones':
            return (0, TravellersPhonesBookingInput_1.TravellersPhonesBookingInputToJSON)(value);
        case 'travellersSurnames':
            return (0, TravellersSurnamesBookingInput_1.TravellersSurnamesBookingInputToJSON)(value);
        case 'travellersTitles':
            return (0, TravellersTitlesBookingInput_1.TravellersTitlesBookingInputToJSON)(value);
        default:
            throw new Error("No variant of BookingInput exists with 'type=".concat(value['type'], "'"));
    }
}
