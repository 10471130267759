"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StaySpecialRequestsBookingChoiceType = void 0;
exports.StaySpecialRequestsBookingChoiceTypeFromJSON = StaySpecialRequestsBookingChoiceTypeFromJSON;
exports.StaySpecialRequestsBookingChoiceTypeFromJSONTyped = StaySpecialRequestsBookingChoiceTypeFromJSONTyped;
exports.StaySpecialRequestsBookingChoiceTypeToJSON = StaySpecialRequestsBookingChoiceTypeToJSON;
/**
 *
 * @export
 */
exports.StaySpecialRequestsBookingChoiceType = {
    StaySpecialRequests: 'staySpecialRequests'
};
function StaySpecialRequestsBookingChoiceTypeFromJSON(json) {
    return StaySpecialRequestsBookingChoiceTypeFromJSONTyped(json, false);
}
function StaySpecialRequestsBookingChoiceTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function StaySpecialRequestsBookingChoiceTypeToJSON(value) {
    return value;
}
