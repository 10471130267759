"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TicketProductType = void 0;
exports.TicketProductTypeFromJSON = TicketProductTypeFromJSON;
exports.TicketProductTypeFromJSONTyped = TicketProductTypeFromJSONTyped;
exports.TicketProductTypeToJSON = TicketProductTypeToJSON;
/**
 *
 * @export
 */
exports.TicketProductType = {
    Travel: 'travel',
    Stay: 'stay'
};
function TicketProductTypeFromJSON(json) {
    return TicketProductTypeFromJSONTyped(json, false);
}
function TicketProductTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function TicketProductTypeToJSON(value) {
    return value;
}
