"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfStaySpecialRequestsBookingChoice = instanceOfStaySpecialRequestsBookingChoice;
exports.StaySpecialRequestsBookingChoiceFromJSON = StaySpecialRequestsBookingChoiceFromJSON;
exports.StaySpecialRequestsBookingChoiceFromJSONTyped = StaySpecialRequestsBookingChoiceFromJSONTyped;
exports.StaySpecialRequestsBookingChoiceToJSON = StaySpecialRequestsBookingChoiceToJSON;
var runtime_1 = require("../runtime");
var StaySpecialRequestsBookingChoiceType_1 = require("./StaySpecialRequestsBookingChoiceType");
/**
 * Check if a given object implements the StaySpecialRequestsBookingChoice interface.
 */
function instanceOfStaySpecialRequestsBookingChoice(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
function StaySpecialRequestsBookingChoiceFromJSON(json) {
    return StaySpecialRequestsBookingChoiceFromJSONTyped(json, false);
}
function StaySpecialRequestsBookingChoiceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, StaySpecialRequestsBookingChoiceType_1.StaySpecialRequestsBookingChoiceTypeFromJSON)(json['type']),
        'specialRequests': !(0, runtime_1.exists)(json, 'specialRequests') ? undefined : json['specialRequests'],
    };
}
function StaySpecialRequestsBookingChoiceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, StaySpecialRequestsBookingChoiceType_1.StaySpecialRequestsBookingChoiceTypeToJSON)(value.type),
        'specialRequests': value.specialRequests,
    };
}
