"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTravellersPassportsBookingInput = instanceOfTravellersPassportsBookingInput;
exports.TravellersPassportsBookingInputFromJSON = TravellersPassportsBookingInputFromJSON;
exports.TravellersPassportsBookingInputFromJSONTyped = TravellersPassportsBookingInputFromJSONTyped;
exports.TravellersPassportsBookingInputToJSON = TravellersPassportsBookingInputToJSON;
var TravellersPassportsBookingInputType_1 = require("./TravellersPassportsBookingInputType");
/**
 * Check if a given object implements the TravellersPassportsBookingInput interface.
 */
function instanceOfTravellersPassportsBookingInput(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "mandatory" in value;
    return isInstance;
}
function TravellersPassportsBookingInputFromJSON(json) {
    return TravellersPassportsBookingInputFromJSONTyped(json, false);
}
function TravellersPassportsBookingInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TravellersPassportsBookingInputType_1.TravellersPassportsBookingInputTypeFromJSON)(json['type']),
        'mandatory': json['mandatory'],
    };
}
function TravellersPassportsBookingInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TravellersPassportsBookingInputType_1.TravellersPassportsBookingInputTypeToJSON)(value.type),
        'mandatory': value.mandatory,
    };
}
