"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConditionDescriptionFromJSON = ConditionDescriptionFromJSON;
exports.ConditionDescriptionFromJSONTyped = ConditionDescriptionFromJSONTyped;
exports.ConditionDescriptionToJSON = ConditionDescriptionToJSON;
var BaggageConditionDescription_1 = require("./BaggageConditionDescription");
var ChangeConditionDescription_1 = require("./ChangeConditionDescription");
var GroupSaveConditionsDescription_1 = require("./GroupSaveConditionsDescription");
var RailcardConditionsDescription_1 = require("./RailcardConditionsDescription");
var RefundConditionDescription_1 = require("./RefundConditionDescription");
var TextualConditionDescription_1 = require("./TextualConditionDescription");
function ConditionDescriptionFromJSON(json) {
    return ConditionDescriptionFromJSONTyped(json, false);
}
function ConditionDescriptionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'baggage':
            return __assign(__assign({}, (0, BaggageConditionDescription_1.BaggageConditionDescriptionFromJSONTyped)(json, true)), { type: 'baggage' });
        case 'change':
            return __assign(__assign({}, (0, ChangeConditionDescription_1.ChangeConditionDescriptionFromJSONTyped)(json, true)), { type: 'change' });
        case 'groupSave':
            return __assign(__assign({}, (0, GroupSaveConditionsDescription_1.GroupSaveConditionsDescriptionFromJSONTyped)(json, true)), { type: 'groupSave' });
        case 'railcard':
            return __assign(__assign({}, (0, RailcardConditionsDescription_1.RailcardConditionsDescriptionFromJSONTyped)(json, true)), { type: 'railcard' });
        case 'refund':
            return __assign(__assign({}, (0, RefundConditionDescription_1.RefundConditionDescriptionFromJSONTyped)(json, true)), { type: 'refund' });
        case 'textual':
            return __assign(__assign({}, (0, TextualConditionDescription_1.TextualConditionDescriptionFromJSONTyped)(json, true)), { type: 'textual' });
        default:
            throw new Error("No variant of ConditionDescription exists with 'type=".concat(json['type'], "'"));
    }
}
function ConditionDescriptionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'baggage':
            return (0, BaggageConditionDescription_1.BaggageConditionDescriptionToJSON)(value);
        case 'change':
            return (0, ChangeConditionDescription_1.ChangeConditionDescriptionToJSON)(value);
        case 'groupSave':
            return (0, GroupSaveConditionsDescription_1.GroupSaveConditionsDescriptionToJSON)(value);
        case 'railcard':
            return (0, RailcardConditionsDescription_1.RailcardConditionsDescriptionToJSON)(value);
        case 'refund':
            return (0, RefundConditionDescription_1.RefundConditionDescriptionToJSON)(value);
        case 'textual':
            return (0, TextualConditionDescription_1.TextualConditionDescriptionToJSON)(value);
        default:
            throw new Error("No variant of ConditionDescription exists with 'type=".concat(value['type'], "'"));
    }
}
