"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCreateUserOrgRequest = instanceOfCreateUserOrgRequest;
exports.CreateUserOrgRequestFromJSON = CreateUserOrgRequestFromJSON;
exports.CreateUserOrgRequestFromJSONTyped = CreateUserOrgRequestFromJSONTyped;
exports.CreateUserOrgRequestToJSON = CreateUserOrgRequestToJSON;
/**
 * Check if a given object implements the CreateUserOrgRequest interface.
 */
function instanceOfCreateUserOrgRequest(value) {
    var isInstance = true;
    isInstance = isInstance && "organisationName" in value;
    isInstance = isInstance && "teamName" in value;
    isInstance = isInstance && "termsAndConditionsAccepted" in value;
    return isInstance;
}
function CreateUserOrgRequestFromJSON(json) {
    return CreateUserOrgRequestFromJSONTyped(json, false);
}
function CreateUserOrgRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'organisationName': json['organisationName'],
        'teamName': json['teamName'],
        'termsAndConditionsAccepted': json['termsAndConditionsAccepted'],
    };
}
function CreateUserOrgRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'organisationName': value.organisationName,
        'teamName': value.teamName,
        'termsAndConditionsAccepted': value.termsAndConditionsAccepted,
    };
}
