"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTravelBookingConditions = instanceOfTravelBookingConditions;
exports.TravelBookingConditionsFromJSON = TravelBookingConditionsFromJSON;
exports.TravelBookingConditionsFromJSONTyped = TravelBookingConditionsFromJSONTyped;
exports.TravelBookingConditionsToJSON = TravelBookingConditionsToJSON;
var ConditionDescription_1 = require("./ConditionDescription");
var TravelBookingConditionsType_1 = require("./TravelBookingConditionsType");
/**
 * Check if a given object implements the TravelBookingConditions interface.
 */
function instanceOfTravelBookingConditions(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "descriptions" in value;
    return isInstance;
}
function TravelBookingConditionsFromJSON(json) {
    return TravelBookingConditionsFromJSONTyped(json, false);
}
function TravelBookingConditionsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TravelBookingConditionsType_1.TravelBookingConditionsTypeFromJSON)(json['type']),
        'descriptions': (json['descriptions'].map(ConditionDescription_1.ConditionDescriptionFromJSON)),
    };
}
function TravelBookingConditionsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TravelBookingConditionsType_1.TravelBookingConditionsTypeToJSON)(value.type),
        'descriptions': (value.descriptions.map(ConditionDescription_1.ConditionDescriptionToJSON)),
    };
}
