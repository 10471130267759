"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingPendingStateType = void 0;
exports.BookingPendingStateTypeFromJSON = BookingPendingStateTypeFromJSON;
exports.BookingPendingStateTypeFromJSONTyped = BookingPendingStateTypeFromJSONTyped;
exports.BookingPendingStateTypeToJSON = BookingPendingStateTypeToJSON;
/**
 *
 * @export
 */
exports.BookingPendingStateType = {
    Pending: 'pending'
};
function BookingPendingStateTypeFromJSON(json) {
    return BookingPendingStateTypeFromJSONTyped(json, false);
}
function BookingPendingStateTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function BookingPendingStateTypeToJSON(value) {
    return value;
}
