"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTravellersMealPreferencesBookingChoice = instanceOfTravellersMealPreferencesBookingChoice;
exports.TravellersMealPreferencesBookingChoiceFromJSON = TravellersMealPreferencesBookingChoiceFromJSON;
exports.TravellersMealPreferencesBookingChoiceFromJSONTyped = TravellersMealPreferencesBookingChoiceFromJSONTyped;
exports.TravellersMealPreferencesBookingChoiceToJSON = TravellersMealPreferencesBookingChoiceToJSON;
var IdTravellerMealPreference_1 = require("./IdTravellerMealPreference");
var TravellersMealPreferencesBookingChoiceType_1 = require("./TravellersMealPreferencesBookingChoiceType");
/**
 * Check if a given object implements the TravellersMealPreferencesBookingChoice interface.
 */
function instanceOfTravellersMealPreferencesBookingChoice(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "mealPreferences" in value;
    return isInstance;
}
function TravellersMealPreferencesBookingChoiceFromJSON(json) {
    return TravellersMealPreferencesBookingChoiceFromJSONTyped(json, false);
}
function TravellersMealPreferencesBookingChoiceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TravellersMealPreferencesBookingChoiceType_1.TravellersMealPreferencesBookingChoiceTypeFromJSON)(json['type']),
        'mealPreferences': (json['mealPreferences'].map(IdTravellerMealPreference_1.IdTravellerMealPreferenceFromJSON)),
    };
}
function TravellersMealPreferencesBookingChoiceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TravellersMealPreferencesBookingChoiceType_1.TravellersMealPreferencesBookingChoiceTypeToJSON)(value.type),
        'mealPreferences': (value.mealPreferences.map(IdTravellerMealPreference_1.IdTravellerMealPreferenceToJSON)),
    };
}
