"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTextualLegalNoticeCondition = instanceOfTextualLegalNoticeCondition;
exports.TextualLegalNoticeConditionFromJSON = TextualLegalNoticeConditionFromJSON;
exports.TextualLegalNoticeConditionFromJSONTyped = TextualLegalNoticeConditionFromJSONTyped;
exports.TextualLegalNoticeConditionToJSON = TextualLegalNoticeConditionToJSON;
var SingleTextualLegalNoticeCondition_1 = require("./SingleTextualLegalNoticeCondition");
var TextualLegalNoticeConditionType_1 = require("./TextualLegalNoticeConditionType");
/**
 * Check if a given object implements the TextualLegalNoticeCondition interface.
 */
function instanceOfTextualLegalNoticeCondition(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "conditions" in value;
    return isInstance;
}
function TextualLegalNoticeConditionFromJSON(json) {
    return TextualLegalNoticeConditionFromJSONTyped(json, false);
}
function TextualLegalNoticeConditionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TextualLegalNoticeConditionType_1.TextualLegalNoticeConditionTypeFromJSON)(json['type']),
        'conditions': (json['conditions'].map(SingleTextualLegalNoticeCondition_1.SingleTextualLegalNoticeConditionFromJSON)),
    };
}
function TextualLegalNoticeConditionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TextualLegalNoticeConditionType_1.TextualLegalNoticeConditionTypeToJSON)(value.type),
        'conditions': (value.conditions.map(SingleTextualLegalNoticeCondition_1.SingleTextualLegalNoticeConditionToJSON)),
    };
}
