"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfLinkLegalNoticeCondition = instanceOfLinkLegalNoticeCondition;
exports.LinkLegalNoticeConditionFromJSON = LinkLegalNoticeConditionFromJSON;
exports.LinkLegalNoticeConditionFromJSONTyped = LinkLegalNoticeConditionFromJSONTyped;
exports.LinkLegalNoticeConditionToJSON = LinkLegalNoticeConditionToJSON;
var LinkLegalNoticeConditionType_1 = require("./LinkLegalNoticeConditionType");
/**
 * Check if a given object implements the LinkLegalNoticeCondition interface.
 */
function instanceOfLinkLegalNoticeCondition(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "conditionsUrl" in value;
    return isInstance;
}
function LinkLegalNoticeConditionFromJSON(json) {
    return LinkLegalNoticeConditionFromJSONTyped(json, false);
}
function LinkLegalNoticeConditionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, LinkLegalNoticeConditionType_1.LinkLegalNoticeConditionTypeFromJSON)(json['type']),
        'conditionsUrl': json['conditionsUrl'],
    };
}
function LinkLegalNoticeConditionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, LinkLegalNoticeConditionType_1.LinkLegalNoticeConditionTypeToJSON)(value.type),
        'conditionsUrl': value.conditionsUrl,
    };
}
