"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookerPhoneNumberBookingInputType = void 0;
exports.BookerPhoneNumberBookingInputTypeFromJSON = BookerPhoneNumberBookingInputTypeFromJSON;
exports.BookerPhoneNumberBookingInputTypeFromJSONTyped = BookerPhoneNumberBookingInputTypeFromJSONTyped;
exports.BookerPhoneNumberBookingInputTypeToJSON = BookerPhoneNumberBookingInputTypeToJSON;
/**
 *
 * @export
 */
exports.BookerPhoneNumberBookingInputType = {
    BookerPhoneNumber: 'bookerPhoneNumber'
};
function BookerPhoneNumberBookingInputTypeFromJSON(json) {
    return BookerPhoneNumberBookingInputTypeFromJSONTyped(json, false);
}
function BookerPhoneNumberBookingInputTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function BookerPhoneNumberBookingInputTypeToJSON(value) {
    return value;
}
