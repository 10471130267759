"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfStartBookingResponse = instanceOfStartBookingResponse;
exports.StartBookingResponseFromJSON = StartBookingResponseFromJSON;
exports.StartBookingResponseFromJSONTyped = StartBookingResponseFromJSONTyped;
exports.StartBookingResponseToJSON = StartBookingResponseToJSON;
var BookingInfo_1 = require("./BookingInfo");
var BookingInput_1 = require("./BookingInput");
var Vendor_1 = require("./Vendor");
/**
 * Check if a given object implements the StartBookingResponse interface.
 */
function instanceOfStartBookingResponse(value) {
    var isInstance = true;
    isInstance = isInstance && "bookingId" in value;
    isInstance = isInstance && "vendor" in value;
    isInstance = isInstance && "bookingInfo" in value;
    isInstance = isInstance && "travellerIds" in value;
    isInstance = isInstance && "bookingInputs" in value;
    return isInstance;
}
function StartBookingResponseFromJSON(json) {
    return StartBookingResponseFromJSONTyped(json, false);
}
function StartBookingResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'bookingId': json['bookingId'],
        'vendor': (0, Vendor_1.VendorFromJSON)(json['vendor']),
        'bookingInfo': (0, BookingInfo_1.BookingInfoFromJSON)(json['bookingInfo']),
        'travellerIds': json['travellerIds'],
        'bookingInputs': (json['bookingInputs'].map(BookingInput_1.BookingInputFromJSON)),
    };
}
function StartBookingResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'bookingId': value.bookingId,
        'vendor': (0, Vendor_1.VendorToJSON)(value.vendor),
        'bookingInfo': (0, BookingInfo_1.BookingInfoToJSON)(value.bookingInfo),
        'travellerIds': value.travellerIds,
        'bookingInputs': (value.bookingInputs.map(BookingInput_1.BookingInputToJSON)),
    };
}
