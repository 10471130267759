"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTravellersTitlesBookingChoice = instanceOfTravellersTitlesBookingChoice;
exports.TravellersTitlesBookingChoiceFromJSON = TravellersTitlesBookingChoiceFromJSON;
exports.TravellersTitlesBookingChoiceFromJSONTyped = TravellersTitlesBookingChoiceFromJSONTyped;
exports.TravellersTitlesBookingChoiceToJSON = TravellersTitlesBookingChoiceToJSON;
var IdTravellerTitle_1 = require("./IdTravellerTitle");
var TravellersTitlesBookingChoiceType_1 = require("./TravellersTitlesBookingChoiceType");
/**
 * Check if a given object implements the TravellersTitlesBookingChoice interface.
 */
function instanceOfTravellersTitlesBookingChoice(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "titles" in value;
    return isInstance;
}
function TravellersTitlesBookingChoiceFromJSON(json) {
    return TravellersTitlesBookingChoiceFromJSONTyped(json, false);
}
function TravellersTitlesBookingChoiceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TravellersTitlesBookingChoiceType_1.TravellersTitlesBookingChoiceTypeFromJSON)(json['type']),
        'titles': (json['titles'].map(IdTravellerTitle_1.IdTravellerTitleFromJSON)),
    };
}
function TravellersTitlesBookingChoiceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TravellersTitlesBookingChoiceType_1.TravellersTitlesBookingChoiceTypeToJSON)(value.type),
        'titles': (value.titles.map(IdTravellerTitle_1.IdTravellerTitleToJSON)),
    };
}
