"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfRegisteredAddress = instanceOfRegisteredAddress;
exports.RegisteredAddressFromJSON = RegisteredAddressFromJSON;
exports.RegisteredAddressFromJSONTyped = RegisteredAddressFromJSONTyped;
exports.RegisteredAddressToJSON = RegisteredAddressToJSON;
var runtime_1 = require("../runtime");
var RegisteredAddressCountry_1 = require("./RegisteredAddressCountry");
/**
 * Check if a given object implements the RegisteredAddress interface.
 */
function instanceOfRegisteredAddress(value) {
    var isInstance = true;
    isInstance = isInstance && "line1" in value;
    isInstance = isInstance && "postcode" in value;
    isInstance = isInstance && "country" in value;
    return isInstance;
}
function RegisteredAddressFromJSON(json) {
    return RegisteredAddressFromJSONTyped(json, false);
}
function RegisteredAddressFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'line1': json['line1'],
        'line2': !(0, runtime_1.exists)(json, 'line2') ? undefined : json['line2'],
        'line3': !(0, runtime_1.exists)(json, 'line3') ? undefined : json['line3'],
        'line4': !(0, runtime_1.exists)(json, 'line4') ? undefined : json['line4'],
        'line5': !(0, runtime_1.exists)(json, 'line5') ? undefined : json['line5'],
        'postcode': json['postcode'],
        'country': (0, RegisteredAddressCountry_1.RegisteredAddressCountryFromJSON)(json['country']),
    };
}
function RegisteredAddressToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'line1': value.line1,
        'line2': value.line2,
        'line3': value.line3,
        'line4': value.line4,
        'line5': value.line5,
        'postcode': value.postcode,
        'country': (0, RegisteredAddressCountry_1.RegisteredAddressCountryToJSON)(value.country),
    };
}
