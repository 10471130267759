"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TravellersGendersBookingChoiceType = void 0;
exports.TravellersGendersBookingChoiceTypeFromJSON = TravellersGendersBookingChoiceTypeFromJSON;
exports.TravellersGendersBookingChoiceTypeFromJSONTyped = TravellersGendersBookingChoiceTypeFromJSONTyped;
exports.TravellersGendersBookingChoiceTypeToJSON = TravellersGendersBookingChoiceTypeToJSON;
/**
 *
 * @export
 */
exports.TravellersGendersBookingChoiceType = {
    TravellersGenders: 'travellersGenders'
};
function TravellersGendersBookingChoiceTypeFromJSON(json) {
    return TravellersGendersBookingChoiceTypeFromJSONTyped(json, false);
}
function TravellersGendersBookingChoiceTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function TravellersGendersBookingChoiceTypeToJSON(value) {
    return value;
}
