"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TravellersMealPreferencesBookingChoiceType = void 0;
exports.TravellersMealPreferencesBookingChoiceTypeFromJSON = TravellersMealPreferencesBookingChoiceTypeFromJSON;
exports.TravellersMealPreferencesBookingChoiceTypeFromJSONTyped = TravellersMealPreferencesBookingChoiceTypeFromJSONTyped;
exports.TravellersMealPreferencesBookingChoiceTypeToJSON = TravellersMealPreferencesBookingChoiceTypeToJSON;
/**
 *
 * @export
 */
exports.TravellersMealPreferencesBookingChoiceType = {
    TravellersMealPreferences: 'travellersMealPreferences'
};
function TravellersMealPreferencesBookingChoiceTypeFromJSON(json) {
    return TravellersMealPreferencesBookingChoiceTypeFromJSONTyped(json, false);
}
function TravellersMealPreferencesBookingChoiceTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function TravellersMealPreferencesBookingChoiceTypeToJSON(value) {
    return value;
}
