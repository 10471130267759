"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTravellersLoyaltyProgrammesBookingChoice = instanceOfTravellersLoyaltyProgrammesBookingChoice;
exports.TravellersLoyaltyProgrammesBookingChoiceFromJSON = TravellersLoyaltyProgrammesBookingChoiceFromJSON;
exports.TravellersLoyaltyProgrammesBookingChoiceFromJSONTyped = TravellersLoyaltyProgrammesBookingChoiceFromJSONTyped;
exports.TravellersLoyaltyProgrammesBookingChoiceToJSON = TravellersLoyaltyProgrammesBookingChoiceToJSON;
var IdTravellerLoyaltyProgrammes_1 = require("./IdTravellerLoyaltyProgrammes");
var TravellersLoyaltyProgrammesBookingChoiceType_1 = require("./TravellersLoyaltyProgrammesBookingChoiceType");
/**
 * Check if a given object implements the TravellersLoyaltyProgrammesBookingChoice interface.
 */
function instanceOfTravellersLoyaltyProgrammesBookingChoice(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "loyaltyProgrammes" in value;
    return isInstance;
}
function TravellersLoyaltyProgrammesBookingChoiceFromJSON(json) {
    return TravellersLoyaltyProgrammesBookingChoiceFromJSONTyped(json, false);
}
function TravellersLoyaltyProgrammesBookingChoiceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TravellersLoyaltyProgrammesBookingChoiceType_1.TravellersLoyaltyProgrammesBookingChoiceTypeFromJSON)(json['type']),
        'loyaltyProgrammes': (json['loyaltyProgrammes'].map(IdTravellerLoyaltyProgrammes_1.IdTravellerLoyaltyProgrammesFromJSON)),
    };
}
function TravellersLoyaltyProgrammesBookingChoiceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TravellersLoyaltyProgrammesBookingChoiceType_1.TravellersLoyaltyProgrammesBookingChoiceTypeToJSON)(value.type),
        'loyaltyProgrammes': (value.loyaltyProgrammes.map(IdTravellerLoyaltyProgrammes_1.IdTravellerLoyaltyProgrammesToJSON)),
    };
}
