"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfBookingPendingState = instanceOfBookingPendingState;
exports.BookingPendingStateFromJSON = BookingPendingStateFromJSON;
exports.BookingPendingStateFromJSONTyped = BookingPendingStateFromJSONTyped;
exports.BookingPendingStateToJSON = BookingPendingStateToJSON;
var BookingPendingStateType_1 = require("./BookingPendingStateType");
/**
 * Check if a given object implements the BookingPendingState interface.
 */
function instanceOfBookingPendingState(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "bookingReference" in value;
    return isInstance;
}
function BookingPendingStateFromJSON(json) {
    return BookingPendingStateFromJSONTyped(json, false);
}
function BookingPendingStateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, BookingPendingStateType_1.BookingPendingStateTypeFromJSON)(json['type']),
        'bookingReference': json['bookingReference'],
    };
}
function BookingPendingStateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, BookingPendingStateType_1.BookingPendingStateTypeToJSON)(value.type),
        'bookingReference': value.bookingReference,
    };
}
