"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyConditionsFromJSON = JourneyConditionsFromJSON;
exports.JourneyConditionsFromJSONTyped = JourneyConditionsFromJSONTyped;
exports.JourneyConditionsToJSON = JourneyConditionsToJSON;
var DirectionConditions_1 = require("./DirectionConditions");
var FloatingConditions_1 = require("./FloatingConditions");
var TravelBookingConditions_1 = require("./TravelBookingConditions");
function JourneyConditionsFromJSON(json) {
    return JourneyConditionsFromJSONTyped(json, false);
}
function JourneyConditionsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'direction':
            return __assign(__assign({}, (0, DirectionConditions_1.DirectionConditionsFromJSONTyped)(json, true)), { type: 'direction' });
        case 'floating':
            return __assign(__assign({}, (0, FloatingConditions_1.FloatingConditionsFromJSONTyped)(json, true)), { type: 'floating' });
        case 'travelBooking':
            return __assign(__assign({}, (0, TravelBookingConditions_1.TravelBookingConditionsFromJSONTyped)(json, true)), { type: 'travelBooking' });
        default:
            throw new Error("No variant of JourneyConditions exists with 'type=".concat(json['type'], "'"));
    }
}
function JourneyConditionsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'direction':
            return (0, DirectionConditions_1.DirectionConditionsToJSON)(value);
        case 'floating':
            return (0, FloatingConditions_1.FloatingConditionsToJSON)(value);
        case 'travelBooking':
            return (0, TravelBookingConditions_1.TravelBookingConditionsToJSON)(value);
        default:
            throw new Error("No variant of JourneyConditions exists with 'type=".concat(value['type'], "'"));
    }
}
