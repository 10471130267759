"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfIdTravellerSurname = instanceOfIdTravellerSurname;
exports.IdTravellerSurnameFromJSON = IdTravellerSurnameFromJSON;
exports.IdTravellerSurnameFromJSONTyped = IdTravellerSurnameFromJSONTyped;
exports.IdTravellerSurnameToJSON = IdTravellerSurnameToJSON;
/**
 * Check if a given object implements the IdTravellerSurname interface.
 */
function instanceOfIdTravellerSurname(value) {
    var isInstance = true;
    isInstance = isInstance && "travellerId" in value;
    isInstance = isInstance && "surname" in value;
    return isInstance;
}
function IdTravellerSurnameFromJSON(json) {
    return IdTravellerSurnameFromJSONTyped(json, false);
}
function IdTravellerSurnameFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'travellerId': json['travellerId'],
        'surname': json['surname'],
    };
}
function IdTravellerSurnameToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'travellerId': value.travellerId,
        'surname': value.surname,
    };
}
