"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTravellersFirstNamesBookingChoice = instanceOfTravellersFirstNamesBookingChoice;
exports.TravellersFirstNamesBookingChoiceFromJSON = TravellersFirstNamesBookingChoiceFromJSON;
exports.TravellersFirstNamesBookingChoiceFromJSONTyped = TravellersFirstNamesBookingChoiceFromJSONTyped;
exports.TravellersFirstNamesBookingChoiceToJSON = TravellersFirstNamesBookingChoiceToJSON;
var IdTravellerFirstName_1 = require("./IdTravellerFirstName");
var TravellersFirstNamesBookingChoiceType_1 = require("./TravellersFirstNamesBookingChoiceType");
/**
 * Check if a given object implements the TravellersFirstNamesBookingChoice interface.
 */
function instanceOfTravellersFirstNamesBookingChoice(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "names" in value;
    return isInstance;
}
function TravellersFirstNamesBookingChoiceFromJSON(json) {
    return TravellersFirstNamesBookingChoiceFromJSONTyped(json, false);
}
function TravellersFirstNamesBookingChoiceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TravellersFirstNamesBookingChoiceType_1.TravellersFirstNamesBookingChoiceTypeFromJSON)(json['type']),
        'names': (json['names'].map(IdTravellerFirstName_1.IdTravellerFirstNameFromJSON)),
    };
}
function TravellersFirstNamesBookingChoiceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TravellersFirstNamesBookingChoiceType_1.TravellersFirstNamesBookingChoiceTypeToJSON)(value.type),
        'names': (value.names.map(IdTravellerFirstName_1.IdTravellerFirstNameToJSON)),
    };
}
