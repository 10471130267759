"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTravellersSurnamesBookingInput = instanceOfTravellersSurnamesBookingInput;
exports.TravellersSurnamesBookingInputFromJSON = TravellersSurnamesBookingInputFromJSON;
exports.TravellersSurnamesBookingInputFromJSONTyped = TravellersSurnamesBookingInputFromJSONTyped;
exports.TravellersSurnamesBookingInputToJSON = TravellersSurnamesBookingInputToJSON;
var TravellersSurnamesBookingInputType_1 = require("./TravellersSurnamesBookingInputType");
/**
 * Check if a given object implements the TravellersSurnamesBookingInput interface.
 */
function instanceOfTravellersSurnamesBookingInput(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
function TravellersSurnamesBookingInputFromJSON(json) {
    return TravellersSurnamesBookingInputFromJSONTyped(json, false);
}
function TravellersSurnamesBookingInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TravellersSurnamesBookingInputType_1.TravellersSurnamesBookingInputTypeFromJSON)(json['type']),
    };
}
function TravellersSurnamesBookingInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TravellersSurnamesBookingInputType_1.TravellersSurnamesBookingInputTypeToJSON)(value.type),
    };
}
