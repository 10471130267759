"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingChoiceFromJSON = BookingChoiceFromJSON;
exports.BookingChoiceFromJSONTyped = BookingChoiceFromJSONTyped;
exports.BookingChoiceToJSON = BookingChoiceToJSON;
var BookerEmailBookingChoice_1 = require("./BookerEmailBookingChoice");
var BookerPhoneNumberBookingChoice_1 = require("./BookerPhoneNumberBookingChoice");
var FlightSeatingOptionsBookingChoice_1 = require("./FlightSeatingOptionsBookingChoice");
var PurchaseOrderNumberBookingChoice_1 = require("./PurchaseOrderNumberBookingChoice");
var StaySpecialRequestsBookingChoice_1 = require("./StaySpecialRequestsBookingChoice");
var TicketCollectionTypeBookingChoice_1 = require("./TicketCollectionTypeBookingChoice");
var TrainSeatingOptionsBookingChoice_1 = require("./TrainSeatingOptionsBookingChoice");
var TravellersBirthdatesBookingChoice_1 = require("./TravellersBirthdatesBookingChoice");
var TravellersEmailsBookingChoice_1 = require("./TravellersEmailsBookingChoice");
var TravellersFirstNamesBookingChoice_1 = require("./TravellersFirstNamesBookingChoice");
var TravellersGendersBookingChoice_1 = require("./TravellersGendersBookingChoice");
var TravellersLoyaltyProgrammesBookingChoice_1 = require("./TravellersLoyaltyProgrammesBookingChoice");
var TravellersMealPreferencesBookingChoice_1 = require("./TravellersMealPreferencesBookingChoice");
var TravellersPassportsBookingChoice_1 = require("./TravellersPassportsBookingChoice");
var TravellersPhonesBookingChoice_1 = require("./TravellersPhonesBookingChoice");
var TravellersSurnamesBookingChoice_1 = require("./TravellersSurnamesBookingChoice");
var TravellersTitlesBookingChoice_1 = require("./TravellersTitlesBookingChoice");
function BookingChoiceFromJSON(json) {
    return BookingChoiceFromJSONTyped(json, false);
}
function BookingChoiceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'bookerEmail':
            return __assign(__assign({}, (0, BookerEmailBookingChoice_1.BookerEmailBookingChoiceFromJSONTyped)(json, true)), { type: 'bookerEmail' });
        case 'bookerPhoneNumber':
            return __assign(__assign({}, (0, BookerPhoneNumberBookingChoice_1.BookerPhoneNumberBookingChoiceFromJSONTyped)(json, true)), { type: 'bookerPhoneNumber' });
        case 'flightSeatingOptions':
            return __assign(__assign({}, (0, FlightSeatingOptionsBookingChoice_1.FlightSeatingOptionsBookingChoiceFromJSONTyped)(json, true)), { type: 'flightSeatingOptions' });
        case 'purchaseOrderNumber':
            return __assign(__assign({}, (0, PurchaseOrderNumberBookingChoice_1.PurchaseOrderNumberBookingChoiceFromJSONTyped)(json, true)), { type: 'purchaseOrderNumber' });
        case 'staySpecialRequests':
            return __assign(__assign({}, (0, StaySpecialRequestsBookingChoice_1.StaySpecialRequestsBookingChoiceFromJSONTyped)(json, true)), { type: 'staySpecialRequests' });
        case 'ticketCollectionType':
            return __assign(__assign({}, (0, TicketCollectionTypeBookingChoice_1.TicketCollectionTypeBookingChoiceFromJSONTyped)(json, true)), { type: 'ticketCollectionType' });
        case 'trainSeatingOptions':
            return __assign(__assign({}, (0, TrainSeatingOptionsBookingChoice_1.TrainSeatingOptionsBookingChoiceFromJSONTyped)(json, true)), { type: 'trainSeatingOptions' });
        case 'travellersBirthdates':
            return __assign(__assign({}, (0, TravellersBirthdatesBookingChoice_1.TravellersBirthdatesBookingChoiceFromJSONTyped)(json, true)), { type: 'travellersBirthdates' });
        case 'travellersEmails':
            return __assign(__assign({}, (0, TravellersEmailsBookingChoice_1.TravellersEmailsBookingChoiceFromJSONTyped)(json, true)), { type: 'travellersEmails' });
        case 'travellersFirstNames':
            return __assign(__assign({}, (0, TravellersFirstNamesBookingChoice_1.TravellersFirstNamesBookingChoiceFromJSONTyped)(json, true)), { type: 'travellersFirstNames' });
        case 'travellersGenders':
            return __assign(__assign({}, (0, TravellersGendersBookingChoice_1.TravellersGendersBookingChoiceFromJSONTyped)(json, true)), { type: 'travellersGenders' });
        case 'travellersLoyaltyProgrammes':
            return __assign(__assign({}, (0, TravellersLoyaltyProgrammesBookingChoice_1.TravellersLoyaltyProgrammesBookingChoiceFromJSONTyped)(json, true)), { type: 'travellersLoyaltyProgrammes' });
        case 'travellersMealPreferences':
            return __assign(__assign({}, (0, TravellersMealPreferencesBookingChoice_1.TravellersMealPreferencesBookingChoiceFromJSONTyped)(json, true)), { type: 'travellersMealPreferences' });
        case 'travellersPassports':
            return __assign(__assign({}, (0, TravellersPassportsBookingChoice_1.TravellersPassportsBookingChoiceFromJSONTyped)(json, true)), { type: 'travellersPassports' });
        case 'travellersPhones':
            return __assign(__assign({}, (0, TravellersPhonesBookingChoice_1.TravellersPhonesBookingChoiceFromJSONTyped)(json, true)), { type: 'travellersPhones' });
        case 'travellersSurnames':
            return __assign(__assign({}, (0, TravellersSurnamesBookingChoice_1.TravellersSurnamesBookingChoiceFromJSONTyped)(json, true)), { type: 'travellersSurnames' });
        case 'travellersTitles':
            return __assign(__assign({}, (0, TravellersTitlesBookingChoice_1.TravellersTitlesBookingChoiceFromJSONTyped)(json, true)), { type: 'travellersTitles' });
        default:
            throw new Error("No variant of BookingChoice exists with 'type=".concat(json['type'], "'"));
    }
}
function BookingChoiceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'bookerEmail':
            return (0, BookerEmailBookingChoice_1.BookerEmailBookingChoiceToJSON)(value);
        case 'bookerPhoneNumber':
            return (0, BookerPhoneNumberBookingChoice_1.BookerPhoneNumberBookingChoiceToJSON)(value);
        case 'flightSeatingOptions':
            return (0, FlightSeatingOptionsBookingChoice_1.FlightSeatingOptionsBookingChoiceToJSON)(value);
        case 'purchaseOrderNumber':
            return (0, PurchaseOrderNumberBookingChoice_1.PurchaseOrderNumberBookingChoiceToJSON)(value);
        case 'staySpecialRequests':
            return (0, StaySpecialRequestsBookingChoice_1.StaySpecialRequestsBookingChoiceToJSON)(value);
        case 'ticketCollectionType':
            return (0, TicketCollectionTypeBookingChoice_1.TicketCollectionTypeBookingChoiceToJSON)(value);
        case 'trainSeatingOptions':
            return (0, TrainSeatingOptionsBookingChoice_1.TrainSeatingOptionsBookingChoiceToJSON)(value);
        case 'travellersBirthdates':
            return (0, TravellersBirthdatesBookingChoice_1.TravellersBirthdatesBookingChoiceToJSON)(value);
        case 'travellersEmails':
            return (0, TravellersEmailsBookingChoice_1.TravellersEmailsBookingChoiceToJSON)(value);
        case 'travellersFirstNames':
            return (0, TravellersFirstNamesBookingChoice_1.TravellersFirstNamesBookingChoiceToJSON)(value);
        case 'travellersGenders':
            return (0, TravellersGendersBookingChoice_1.TravellersGendersBookingChoiceToJSON)(value);
        case 'travellersLoyaltyProgrammes':
            return (0, TravellersLoyaltyProgrammesBookingChoice_1.TravellersLoyaltyProgrammesBookingChoiceToJSON)(value);
        case 'travellersMealPreferences':
            return (0, TravellersMealPreferencesBookingChoice_1.TravellersMealPreferencesBookingChoiceToJSON)(value);
        case 'travellersPassports':
            return (0, TravellersPassportsBookingChoice_1.TravellersPassportsBookingChoiceToJSON)(value);
        case 'travellersPhones':
            return (0, TravellersPhonesBookingChoice_1.TravellersPhonesBookingChoiceToJSON)(value);
        case 'travellersSurnames':
            return (0, TravellersSurnamesBookingChoice_1.TravellersSurnamesBookingChoiceToJSON)(value);
        case 'travellersTitles':
            return (0, TravellersTitlesBookingChoice_1.TravellersTitlesBookingChoiceToJSON)(value);
        default:
            throw new Error("No variant of BookingChoice exists with 'type=".concat(value['type'], "'"));
    }
}
