"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCollectionRefTicket = instanceOfCollectionRefTicket;
exports.CollectionRefTicketFromJSON = CollectionRefTicketFromJSON;
exports.CollectionRefTicketFromJSONTyped = CollectionRefTicketFromJSONTyped;
exports.CollectionRefTicketToJSON = CollectionRefTicketToJSON;
var runtime_1 = require("../runtime");
var CollectionRefTicketType_1 = require("./CollectionRefTicketType");
var FormattedDate_1 = require("./FormattedDate");
var TicketProductType_1 = require("./TicketProductType");
/**
 * Check if a given object implements the CollectionRefTicket interface.
 */
function instanceOfCollectionRefTicket(value) {
    var isInstance = true;
    isInstance = isInstance && "productType" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "reference" in value;
    return isInstance;
}
function CollectionRefTicketFromJSON(json) {
    return CollectionRefTicketFromJSONTyped(json, false);
}
function CollectionRefTicketFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'productType': (0, TicketProductType_1.TicketProductTypeFromJSON)(json['productType']),
        'confirmationDate': !(0, runtime_1.exists)(json, 'confirmationDate') ? undefined : (0, FormattedDate_1.FormattedDateFromJSON)(json['confirmationDate']),
        'type': (0, CollectionRefTicketType_1.CollectionRefTicketTypeFromJSON)(json['type']),
        'reference': json['reference'],
    };
}
function CollectionRefTicketToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'productType': (0, TicketProductType_1.TicketProductTypeToJSON)(value.productType),
        'confirmationDate': (0, FormattedDate_1.FormattedDateToJSON)(value.confirmationDate),
        'type': (0, CollectionRefTicketType_1.CollectionRefTicketTypeToJSON)(value.type),
        'reference': value.reference,
    };
}
