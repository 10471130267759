"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfSingleTextualLegalNoticeCondition = instanceOfSingleTextualLegalNoticeCondition;
exports.SingleTextualLegalNoticeConditionFromJSON = SingleTextualLegalNoticeConditionFromJSON;
exports.SingleTextualLegalNoticeConditionFromJSONTyped = SingleTextualLegalNoticeConditionFromJSONTyped;
exports.SingleTextualLegalNoticeConditionToJSON = SingleTextualLegalNoticeConditionToJSON;
/**
 * Check if a given object implements the SingleTextualLegalNoticeCondition interface.
 */
function instanceOfSingleTextualLegalNoticeCondition(value) {
    var isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "body" in value;
    return isInstance;
}
function SingleTextualLegalNoticeConditionFromJSON(json) {
    return SingleTextualLegalNoticeConditionFromJSONTyped(json, false);
}
function SingleTextualLegalNoticeConditionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'title': json['title'],
        'body': json['body'],
    };
}
function SingleTextualLegalNoticeConditionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'title': value.title,
        'body': value.body,
    };
}
