"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TerminalBookingStateFromJSON = TerminalBookingStateFromJSON;
exports.TerminalBookingStateFromJSONTyped = TerminalBookingStateFromJSONTyped;
exports.TerminalBookingStateToJSON = TerminalBookingStateToJSON;
var BookingConfirmedState_1 = require("./BookingConfirmedState");
var BookingFailedState_1 = require("./BookingFailedState");
var BookingPartiallyFailedState_1 = require("./BookingPartiallyFailedState");
var BookingPendingState_1 = require("./BookingPendingState");
function TerminalBookingStateFromJSON(json) {
    return TerminalBookingStateFromJSONTyped(json, false);
}
function TerminalBookingStateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'confirmed':
            return __assign(__assign({}, (0, BookingConfirmedState_1.BookingConfirmedStateFromJSONTyped)(json, true)), { type: 'confirmed' });
        case 'failed':
            return __assign(__assign({}, (0, BookingFailedState_1.BookingFailedStateFromJSONTyped)(json, true)), { type: 'failed' });
        case 'partiallyFailed':
            return __assign(__assign({}, (0, BookingPartiallyFailedState_1.BookingPartiallyFailedStateFromJSONTyped)(json, true)), { type: 'partiallyFailed' });
        case 'pending':
            return __assign(__assign({}, (0, BookingPendingState_1.BookingPendingStateFromJSONTyped)(json, true)), { type: 'pending' });
        default:
            throw new Error("No variant of TerminalBookingState exists with 'type=".concat(json['type'], "'"));
    }
}
function TerminalBookingStateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'confirmed':
            return (0, BookingConfirmedState_1.BookingConfirmedStateToJSON)(value);
        case 'failed':
            return (0, BookingFailedState_1.BookingFailedStateToJSON)(value);
        case 'partiallyFailed':
            return (0, BookingPartiallyFailedState_1.BookingPartiallyFailedStateToJSON)(value);
        case 'pending':
            return (0, BookingPendingState_1.BookingPendingStateToJSON)(value);
        default:
            throw new Error("No variant of TerminalBookingState exists with 'type=".concat(value['type'], "'"));
    }
}
